import SearchInput from "./SearchInput";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function SearchLanding() {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const handleFormSubmit = (e) => {
    navigate(`/search?q=${query}`);
  };

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
  };

  return (
    <div className="App">
      <h1 className="text-center text-3xl font-bold mt-32">ClassCrawler</h1>

      <div className="flex flex-col justify-center items-center">
        <div className="w-1/3 mt-5">
          <SearchInput
            onQueryChange={handleQueryChange}
            onSubmit={handleFormSubmit}
          />
        </div>
        {/* <h2 className="mt-4">300,000 CS resources indexed</h2> */}
        <h2 className="mt-4">Search 599,873 CS lecture notes, slides, videos, and more</h2>
      </div>
      <div className="fixed bottom-0 left-0 z-50 bg-white px-4 py-2 w-full">
        <a href="mailto:hi@classcrawler.com">
          <h1 className="text-md font-normal">Contact</h1>
        </a>
      </div>
    </div>
  );
}
export default SearchLanding;
