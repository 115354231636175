import { useLocation, useNavigate, Link } from "react-router-dom";
import SearchInput from "./SearchInput";
import { useState, useEffect } from "react";

function SearchResults() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [query, setQuery] = useState(queryParams.get("q"));

  const navigate = useNavigate();
  const [searchNewQuery, setSearchNewQuery] = useState("");

  // 6-16
  const [webpages, setWebpages] = useState([])
  const [PDFs, setPDFs] = useState([]);
  const [PPTXs, setPPTXs] = useState([]);
  const [videos, setVideos] = useState([]);

  const [showingWebpages, setShowingWebpages] = useState(true);
  const [showingPDFs, setShowingPDFs] = useState(false);
  const [showingPPTXs, setShowingPPTXs] = useState(false);
  const [showingVideos, setShowingVideos] = useState(false);

  const handleQueryChange = (newQuery) => {
    setSearchNewQuery(newQuery);
  };

  useEffect(() => {
     async function generateAnswers(userQuery){

    // Get sources to use
    console.log(`Input value: ${userQuery}`)
    fetch('https://schoolattendance.us/getdocuments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_query: String(userQuery),
          sources: false,
        }),
      })
        .then(response => response.json())
        .then(data => {
          // Handle the response data here
          const processedHTML = Object.values(data.html).map(obj => ({url: obj.url, title: obj.title, description: obj.description}));
          setWebpages(processedHTML);
          const processedPPTX = Object.values(data.pptx).map(obj => ({url: obj.url, title: obj.title, description: obj.description, author: obj.author, date: obj.date}));
          setPPTXs(processedPPTX)
          const processedPDF = Object.values(data.pdf).map(obj => ({url: obj.url, title: obj.title, description: obj.description, author: obj.author, date: obj.date}));
          setPDFs(processedPDF)
          const processedMP4 = Object.values(data.mp4).map(obj => ({url: obj.url, title: obj.title, description: obj.description, image: obj.image}));
          setVideos(processedMP4);
          console.log(processedMP4);
          //source_1 = `Excerpt: ${data.documents[0].content}\nSource: ${data.documents[0].url}\n`
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error('Error:', error);
    });
  }
    generateAnswers(query);
    return () => {};
  }, []);

  const handleFormSubmit = (e) => {
    navigate(`/search?q=${searchNewQuery}`);
    generateAnswers(searchNewQuery);
  };

  async function generateAnswers(userQuery){

    // Get sources to use
    console.log(`Input value: ${userQuery}`)
    fetch('https://schoolattendance.us/getdocuments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_query: String(userQuery),
          sources: false,
        }),
      })
        .then(response => response.json())
        .then(data => {
          // Handle the response data here
          const processedHTML = Object.values(data.html).map(obj => ({url: obj.url, title: obj.title, description: obj.description}));
          setWebpages(processedHTML);
          const processedPPTX = Object.values(data.pptx).map(obj => ({url: obj.url, title: obj.title, description: obj.description, author: obj.author, date: obj.date}));
          setPPTXs(processedPPTX)
          const processedPDF = Object.values(data.pdf).map(obj => ({url: obj.url, title: obj.title, description: obj.description, author: obj.author, date: obj.date}));
          setPDFs(processedPDF)
          const processedMP4 = Object.values(data.mp4).map(obj => ({url: obj.url, title: obj.title, description: obj.description, image: obj.image}));
          setVideos(processedMP4);
          console.log(processedMP4);
          //source_1 = `Excerpt: ${data.documents[0].content}\nSource: ${data.documents[0].url}\n`
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error('Error:', error);
    });
  }

  // Description: Changes which results are being viewed
  const handleResultOptionChange = (resultToChange) => {
    if (resultToChange === "pdfs") {
      setShowingWebpages(false);
      setShowingPDFs(true);
      setShowingPPTXs(false);
      setShowingVideos(false);
      return
    }
    if (resultToChange === "videos") {
      setShowingWebpages(false);
      setShowingPDFs(false);
      setShowingPPTXs(false);
      setShowingVideos(true);
      return
    }
    if (resultToChange === "webpages"){
      setShowingWebpages(true);
      setShowingPDFs(false);
      setShowingPPTXs(false);
      setShowingVideos(false);
      return
    }
    if (resultToChange === "pptxs") {
      setShowingWebpages(false);
      setShowingPDFs(false);
      setShowingPPTXs(true);
      setShowingVideos(false);
      return
    }
  }

  return (
    <div>
      {/* Search bar and logo */}
      <header className="flex justify-between items-center py-4 px-8 border-b border-gray-300">
        <div className="flex items-center">
          <Link to="/">
            <h1 className="text-center text-3xl font-bold mr-5">
              ClassCrawler
            </h1>
          </Link>
          <SearchInput
            placeholderText={query}
            onQueryChange={handleQueryChange}
            onSubmit={handleFormSubmit}
          />
        </div>
      </header>

      {/* Result option menu */}
      <div className="my-4 "></div>
      <div className="flex flex-row justify-between w-1/4 ml-8">
        <div className="">
          <button 
            type="button" 
            onClick={() => handleResultOptionChange("webpages")} 
            className={`bg-white text-sm text-black rounded-sm hover:border-b-2 hover:border-black ${
              showingWebpages ? 'border-b-2 border-green-700' : ''
            } pl-2 pr-2 pt-2 pb-2 font-light`}>
            Webpages
          </button>
        </div>
        <div className="">
          <button 
            type="button" 
            onClick={() => handleResultOptionChange("pdfs")}
            className={`bg-white text-sm text-black rounded-sm hover:border-b-2 hover:border-black ${
              showingPDFs ? 'border-b-2 border-green-700' : ''
            } pl-2 pr-2 pt-2 pb-2 font-light`}>
              PDFs
            </button>
        </div>
        <div className="">
          <button 
            type="button" 
            onClick={() => handleResultOptionChange("pptxs")} 
            className={`bg-white text-sm text-black rounded-sm hover:border-b-2 hover:border-black ${
              showingPPTXs ? 'border-b-2 border-green-700' : ''
            } pl-2 pr-2 pt-2 pb-2 font-light`}>
            Powerpoints
          </button>
        </div>
        <div className="">
          <button 
            type="button" 
            onClick={() => handleResultOptionChange("videos")}
            className={`bg-white text-sm text-black rounded-sm hover:border-b-2 hover:border-black ${
              showingVideos ? 'border-b-2 border-green-700' : ''
            } pl-2 pr-2 pt-2 pb-2 font-light`}>
            Videos
          </button>
        </div>
      </div>
      {/* Listed results */}
      <div className="ml-8 mt-8">
        <div>
          {showingWebpages && (
            <>
              {webpages && webpages.length > 0 ? (
                webpages.map((result, index) => (
                  <div key={index} className="pt-4 pb-4 w-1/2">
                    <a href={result.url} className="hover:text-blue-500" target="_blank" rel="noreferrer">
                      <h1 className="text-md font-semibold">{result.title}</h1>
                    </a>
                    <h1 className="text-xs font-normal mt-1 mb-1">{result.url}</h1>
                    <h1 className="text-sm font-light">{result.description}</h1>
                  </div>
                ))
              ) : (
                <p>Could not find any webpages. Try another query.</p>
              )}
            </>
          )}
          {showingPDFs && (
            <>
              {PDFs && PDFs.length > 0 ? (
                PDFs.map((result, index) => (
                  <div key={index} className="pt-4 pb-4 w-1/2">
                    <a href={result.url} className="hover:text-blue-500" target="_blank" rel="noreferrer">
                      <h1 className="text-md font-semibold">{result.title}</h1>
                    </a>
                    <h1 className="text-xs font-normal mt-1 mb-1">{result.url}</h1>
                    <h1 className="text-sm font-light">{result.description}</h1>
                    {/* <h1 className="text-sm font-light">{result.author}</h1>
                    <h1 className="text-sm font-light">{result.date}</h1> */}
                  </div>
                ))
              ) : (
                <p>Could not find any PDFs. Try another query.</p>
              )}
            </>
          )}
          {showingPPTXs && (
            <>
              {PPTXs && PPTXs.length > 0 ? (
                PPTXs.map((result, index) => (
                  <div key={index} className="pt-4 pb-4 w-1/2">
                    <a href={result.url} className="hover:text-blue-500 w-4/5" target="_blank" rel="noreferrer">
                      <h1 className="text-md font-semibold">{result.title}</h1>
                    </a>
                    <div className="">
                      <h1 className="text-xs font-light mt-1 mb-1">{result.url}</h1>
                    </div>
                    <div className="w-4/5">
                      <h1 className="text-sm font-light">{result.description}</h1>
                    </div>
                    {/* <h1 className="text-sm font-light">{result.author}</h1>
                    <h1 className="text-sm font-light">{result.date}</h1> */}
                  </div>
                ))
              ) : (
                <p>Could not find any PPTXs. Try another query.</p>
              )}
            </>
          )}
          {showingVideos && (
            <>
              {videos && videos.length > 0 ? (
                videos.map((result, index) => (
                  <div key={index} className="pt-4 pb-4 w-1/2">
                    <img src={result.image} alt="image of webpage" className="w-48 h-auto rounded-md mb-2"></img>
                    <a href={result.url} className="hover:text-blue-500" target="_blank" rel="noreferrer">
                      <h1 className="text-md font-semibold">{result.title}</h1>
                    </a>
                    <h1 className="text-xs font-normal mt-1 mb-1">{result.url}</h1>
                    <h1 className="text-sm font-light">{result.description}</h1>
                  </div>
                ))
              ) : (
                <p>Could not find any videos. Try another query.</p>
              )}
            </>
          )}
          {!showingWebpages && !showingPDFs && !showingPPTXs && !showingVideos && (
            <p>Could not find any content. Try another query.</p>
          )}
        </div>
        {/* <div>
        {webpages && webpages.length > 0 ? (
          webpages.map((result, index) => (
            <div key={index} className="pt-4 pb-4 w-1/3">
              <a href={result.url} className="hover:text-blue-500">
                <h1 className="text-md font-semibold">{result.title}</h1>
              </a>
              <h1 className="text-sm font-light">{result.description}</h1>
            </div>
          ))
        ) : (
          <p>Could not find any webpages. Try another query.</p>
        )}
        </div> */}
      </div>
    </div>
  );
}

export default SearchResults;
